<template>
  <div>
    <div class="card card-custom gutter-b" style="height: 60vh; overflow-y: auto;">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Month
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
        <div class="card-body">
          <VueApexCharts
            v-if="isReady"
            :options="options"
            :series="series"
          ></VueApexCharts>
        </div>
      </div>
    </div>
</template>


<script>
import parse from 'date-fns/parse'
import getMonth from 'date-fns/getMonth'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'MonthTraffic',
  components: {
    VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      transactions: [],
      tokenTransactions: [],
      tokenDropTransactions: [],
      coinTransactions: [],
      smsTransactions: [],
      loyaltyTransactions: [],
      cardTransactions: [],
      totalMonthAmount0: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount1: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount2: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount3: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount4: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount5: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount6: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount7: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount8: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount9: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount10: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      totalMonthAmount11: {
        token: { name: '', total: 0 },
        tokenDrop: { name: '', total: 0 },
        coin: { name: '', total: 0 },
        sms: { name: '', total: 0 },
        loyalty: { name: '', total: 0 },
        card: { name: '', total: 0 },
      },
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            if (val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              )
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                )
              }
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
          color: '#FEB019',
        },
        {
          name: 'TokenDrop',
          data: [],
          color: '#AAAAAA'
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.traffic.forEach((transaction) => {
      if(transaction.amount >= 0){
        this.transactions.push({
          month: getMonth(
            parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date())
          ),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
        })
      }
    })

    this.tokenTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_token'
    )

    this.tokenDropTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'token_drop'
    )

    this.smsTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_sms'
    )

    this.loyaltyTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard'
    )

    this.coinTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
    )

     this.cardTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_card'
    )

    const monthNames = [
      'January', 
      'February', 
      'March', 
      'April', 
      'May', 
      'June', 
      'July', 
      'August', 
      'September', 
      'October', 
      'November', 
      'December'
    ];

    this.tokenTransactions.forEach((transaction) => {
      const monthIndex = transaction.month;
      this[`totalMonthAmount${monthIndex}`].token.total += parseFloat(transaction.amount);
      
      const monthName = monthNames[monthIndex];
      if (!this.options.xaxis.categories.includes(monthName)) {
        this.options.xaxis.categories.push(monthName);
      }
    });

    this.tokenDropTransactions.forEach((transaction) => {
      const monthIndex = transaction.month;
      this[`totalMonthAmount${monthIndex}`].tokenDrop.total += parseFloat(transaction.amount);
      
      const monthName = monthNames[monthIndex];
      if (!this.options.xaxis.categories.includes(monthName)) {
        this.options.xaxis.categories.push(monthName);
      }
    });

    this.coinTransactions.forEach((transaction) => {
      const monthIndex = transaction.month;
      this[`totalMonthAmount${monthIndex}`].coin.total += parseFloat(transaction.amount);
      
      const monthName = monthNames[monthIndex];
      if (!this.options.xaxis.categories.includes(monthName)) {
        this.options.xaxis.categories.push(monthName);
      }
    });

    this.smsTransactions.forEach((transaction) => {
      const monthIndex = transaction.month;
      this[`totalMonthAmount${monthIndex}`].sms.total += parseFloat(transaction.amount);
      
      const monthName = monthNames[monthIndex];
      if (!this.options.xaxis.categories.includes(monthName)) {
        this.options.xaxis.categories.push(monthName);
      }
    });

    this.loyaltyTransactions.forEach((transaction) => {
      const monthIndex = transaction.month;
      this[`totalMonthAmount${monthIndex}`].loyalty.total += parseFloat(transaction.amount);
      
      const monthName = monthNames[monthIndex];
      if (!this.options.xaxis.categories.includes(monthName)) {
        this.options.xaxis.categories.push(monthName);
      }
    });

    this.cardTransactions.forEach((transaction) => {
      const monthIndex = transaction.month;
      this[`totalMonthAmount${monthIndex}`].card.total += parseFloat(transaction.amount);
      
      const monthName = monthNames[monthIndex];
      if (!this.options.xaxis.categories.includes(monthName)) {
        this.options.xaxis.categories.push(monthName);
      }
    });
    
    for (let i = 0; i <= 11; i++) {
      const totalMonthTokenDropAmount = this[`totalMonthAmount${i}`]?.tokenDrop?.total;
      const totalMonthCoinAmount = this[`totalMonthAmount${i}`]?.coin?.total;
      const totalMonthCardAmount = this[`totalMonthAmount${i}`]?.card?.total;
      if (totalMonthTokenDropAmount) {
        this.series[2].data.push(totalMonthTokenDropAmount);
      }
      if (totalMonthCoinAmount) {
        this.series[0].data.push(totalMonthCoinAmount);
      }
      if (totalMonthCardAmount) {
        this.series[1].data.push(totalMonthCardAmount);
      }
    }

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    notFullMonth() {
      return 1
    },
  },
}
</script>
