<template>
  <div style="height: 60vh;">
    <div class="card card-custom gutter-b" style="height: 60vh; overflow-y: auto;">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title">
          Year
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div class="card-body">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import parse from 'date-fns/parse'
import getYear from 'date-fns/getYear'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'YearTraffic',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      transactions: [],
      tokenTransactions: [],
      tokenDropTransactions: [],
      coinTransactions: [],
      smsTransactions: [],
      loyaltyTransactions: [],
      cardTransactions: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            )
          },
        },
        xaxis: {
          categories: [new Date().getFullYear()],
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              )
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
          color: '#FEB019',
        },
        {
          name: 'TokenDrop',
          data: [],
          color: "#AAAAAA"
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.traffic.forEach((transaction) => {
      if(transaction.amount >= 0) {
        this.transactions.push({
          year: getYear(
            parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date())
          ),
          amount: transaction.amount,
          paymentType: transaction.paymentType,
        })
      }
    })

    this.tokenTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_token'
    )

    this.tokenDropTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'token_drop'
    )

    this.coinTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_coin' ||
        transaction.paymentType === 'pay_cctalk' ||
        transaction.paymentType === 'pay_mdb' ||
        transaction.paymentType === 'pay_sci'
    )

    this.smsTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_sms'
    )

    this.loyaltyTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_rfcard'
    )

    this.cardTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_card'
    )

    let totalTokenDrop = 0
    let totalCoin = 0
    let totalCard = 0

    this.tokenDropTransactions.forEach((transaction) => {
      totalTokenDrop += parseFloat(transaction.amount)
    })
    this.coinTransactions.forEach((transaction) => {
      totalCoin += parseFloat(transaction.amount)
    })
    this.cardTransactions.forEach((transaction) => {
      totalCard += parseFloat(transaction.amount)
    })

    this.series[2].data.push(totalTokenDrop)
    this.series[0].data.push(totalCoin)
    this.series[1].data.push(totalCard)

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {},
}
</script>
